import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Deficit Deadlifts 4-4-4-4 to a 4RM`}</p>
    <p>{`then, `}<em parentName="p">{`(using 1 bar)`}</em></p>
    <p>{`20 Deadlifts (135/95)`}</p>
    <p>{`20 Bar Facing Burpees`}</p>
    <p>{`15 Deadlifts (185/125)`}</p>
    <p>{`15 Bar Facing Burpees`}</p>
    <p>{`10 Deadlifts (225/155)`}</p>
    <p>{`10 Bar Facing Burpees`}</p>
    <p>{`5 Deadlifts (275/185)`}</p>
    <p>{`5 Bar Facing Burpees`}</p>
    <p>{`For time.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      